import { useEffect, useState } from 'react';
import { getAllDatabases as getAllDatabases } from '../../../../apis/datasources/apis';
import { DatabaseDto } from '../../../../apis/datasources/interfaces';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  addDatabaseModalAtom,
  curDatabaseAtom,
  databasesAtom,
  addDatabaseRedashAtom,
} from '../../../../recoil/atoms/atoms';

export const Databases = () => {
  const [databases, setDatabases] = useState<DatabaseDto[]>([]);
  const [addDatabaseModal, setAddDatabaseModal] =
    useRecoilState(addDatabaseModalAtom);
  const [addDatabaseRedash, setAddDatabaseRedash] = useRecoilState(addDatabaseRedashAtom);
  const setCurDatabase = useSetRecoilState(curDatabaseAtom);
  const setDatabasesMap = useSetRecoilState(databasesAtom);

  useEffect(() => {
    getAllDatabases().then((dbList) => {
      setDatabases(dbList);
      const dbMap = new Map();
      dbList.forEach((dbItem) => {
        dbMap.set(dbItem.id, dbItem);
      });
      setDatabasesMap(dbMap);
    });
  }, [addDatabaseModal, addDatabaseRedash]);

  const handleManageDatabase = (database: DatabaseDto) => {
    setCurDatabase(database);
    if (database.connVia === 'redash') {
      setAddDatabaseRedash(true);
    } else {
      setAddDatabaseModal(true);
    }
  };

  const renderDatabases = () => {
    return databases.map((database) => {
      return (
        <div
          key={database.id}
          className="flex gap-x-6 px-16 py-7 justify-between items-center w-full h-12 rounded hover:bg-layer2"
        >
          <p className="w-1/6">{database.id}</p>
          <p className="w-1/6 text-text_selected font-bold">{database.name}</p>
          <div className="w-1/6 text-text_unselected">
            <div className="w-24 truncate" title={database.createdOn}>
              {database.createdOn}
            </div>
          </div>
          <div className="w-1/6">
            <div
              className="w-24 truncate"
              title={JSON.stringify(database.connJson)}
            >
              {JSON.stringify(database.connJson)}
            </div>
          </div>
          <p className="w-1/6 text-text_unselected">{database.type}</p>
          <div
            className="flex w-1/12 items-center justify-center cursor-pointer"
            onClick={() => handleManageDatabase(database)}
          >
            <div className="flex items-center justify-center px-6 h-8 border rounded border-active_hover">
              <span>Manage</span>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="px-6 h-[60vh] w-full">
      <div className="flex items-center gap-3 text-2xl font-bold font-mono">
        <span className="w-6 h-6 bg-database" />
        Databases
      </div>
      <div
        className={`bg-layer2 mt-3 w-full h-full rounded ${databases.length === 0 && 'text-text_unselected'
        }`}
      >
        <div className="overflow-auto w-full h-full">
          <div className="flex gap-x-6 px-16 justify-between items-center w-full h-12 rounded-t bg-layer2 font-bold">
            <span className="w-1/6">ID</span>
            <span className="w-1/6">Database Name</span>
            <span className="w-1/6">Modified At</span>
            <span className="w-1/6">Database</span>
            <span className="w-1/6">Type</span>
            <div className="w-1/12" />
          </div>
          <div className="w-full border-t border-active_hover" />

          <div className="flex flex-col h-full rounded text-text_unselected">
            {databases.length ? (
              <div className="flex-col items-center text-semibold">
                {renderDatabases()}
              </div>
            ) : (
              <div className="h-full flex-1 flex items-center justify-center text-semibold">
                <span>
                  There are currently no database connected to your account.
                  <br />
                  Lets start by importing a new database for enhanced results.
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-row p-3 mt-[-3px] gap-5 rounded-b bg-layer2">
          {/* Hide Native Connection Button */}
          {/* <div
            className="flex gap-1 items-center justify-center h-10 w-44 bg-border_unselected rounded cursor-pointer"
            onClick={() => {
              setCurDatabase(null);
              setAddDatabaseModal(true);
            }}
          >
            <div className="w-6 h-6 bg-blueplus" />
            <span className=" text-text_selected text-sm text-semibold">
              Add New Database
            </span>
          </div> */}

          {/* Hide Redash Button */}
          <div
            className="flex gap-1 items-center justify-center h-10 w-44 bg-border_unselected rounded cursor-pointer"
            onClick={() => {
              setCurDatabase(null);
              setAddDatabaseRedash(true);
            }}
          >
            <div className="w-6 h-6 bg-blueplus" />
            <span className=" text-text_selected text-sm text-semibold">
              Connect to Database
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
