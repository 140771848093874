import { useState } from 'react';
import { OTPInput } from './otp';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { authAtom, registerAtom } from '../../../recoil/atoms/atoms';
import { CreateUserDto, LoginDto } from '../../../apis/auth/interfaces';
import {
  getEmailVerificationCode,
  loginWithCreds,
  registerUser,
} from '../../../apis/auth/apis';
import Swal from 'sweetalert2';

export const Email = () => {
  const navigate = useNavigate();

  const [otp, setOtp] = useState('');
  const setAuthInfo = useSetRecoilState(authAtom);
  const [registerInfo, setRegisterInfo] = useRecoilState(registerAtom);

  const onResendClick = () => {
    getEmailVerificationCode(registerInfo.email).then(() => {
      Swal.fire({ title: '', text: 'Please check your inbox!', icon: 'info' });
    });
  };

  const onVerifyClick = () => {
    registerInfo.emailCode = otp;
    setRegisterInfo(registerInfo);
    const createUserInfo: CreateUserDto = {
      username: registerInfo.username,
      password: registerInfo.password,
      type: registerInfo.type,
      email: registerInfo.email,
      emailCode: otp,
      invite_id: registerInfo.invite_id,
    };

    registerUser(createUserInfo).then(() => {
      const loginDto: LoginDto = {
        username: registerInfo.username,
        password: registerInfo.password,
      };
      loginWithCreds(loginDto).then((authInfo) => {
        if (authInfo) {
          localStorage.setItem('authInfo', JSON.stringify(
            {
              ...authInfo,
              authInfoTime: new Date().getTime().toString()
            }));
          setAuthInfo(authInfo);
          navigate('/');
          localStorage.removeItem('onboardShown');
        }
      });
    });
  };

  return (
    <div className="grid flex justify-items-center justify-self-center self-center w-1/3 h-auto bg-layer2 max-w-md">
      <div className="text-xl font-bold text-text_selected w-5/6 my-10 text-center flex flex-col items-center">
        <div className="w-14 h-14 bg-envelope" />
        <span className="mt-4 text-2xl text-text_selected text-bold">
          Verify your Email
        </span>
        <span className="mt-4 text-xs text-text_unselected">
          A verification code has been sent to your email address
        </span>
        <OTPInput value={otp} onChange={setOtp} numInputs={6} />
        <p className="mt-4 text-xs text-text_selected" onClick={onResendClick}>
          Resend Code
        </p>
        <div
          className="mt-6 w-full h-12 rounded bg-icon_unselected grid items-center cursor-pointer"
          onClick={onVerifyClick}
        >
          <span className="text-black dark:text-white text-sm font-semibold">Verify</span>
        </div>
      </div>
    </div>
  );
};
