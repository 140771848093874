import { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { Navigate } from 'react-router-dom';
import { authAtom } from '../recoil/atoms/atoms';
import Swal from 'sweetalert2';
import { useAuth0 } from '@auth0/auth0-react';

const ProtectedRoute = ({ children }) => {
  const authInfo = useRecoilValue(authAtom);
  const cushion = 6 * 60 * 1000;

  const resetAuthInfo = useResetRecoilState(authAtom);
  const { isAuthenticated, isLoading } = useAuth0();

  const checkGoogleValidity = () => {
    if (authInfo) {
      const currentTime = new Date().getTime();
      const expireTime = authInfo.expireAt ?? 0;
      if (currentTime + cushion < expireTime) {
        return true;
      } else {
        localStorage.removeItem('authInfo');
        resetAuthInfo();
        Swal.fire('Error', 'Session Ended, Please login in again!', 'error');
        return false;
      }
    }
  };

  useEffect(() => {
    const handleAuthCheck = () => {
      // Check if Okta Auth0 session is not active
      if (!isAuthenticated && !isLoading) {
        checkGoogleValidity();
      }
    };

    handleAuthCheck();  // Initial check on mount and auth state changes

    const intervalId = setInterval(checkGoogleValidity, 1000 * 60 * 60); // Check validity every hour

    return () => {
      clearInterval(intervalId); // Cleanup the interval
    };
  }, [authInfo, resetAuthInfo]);

  // Combined authentication check for Google and Auth0
  const isAuthenticatedOverall = checkGoogleValidity() || isAuthenticated || !isLoading;

  if (isAuthenticatedOverall) {
    return children; // Render protected content if authenticated
  }

  return <Navigate to="/landing" replace />;
};

export default ProtectedRoute;
