import { useEffect, useMemo, useRef } from 'react';
import Plotly from 'plotly.js-dist';

interface Ai_ChartProps {
  ai_chart: string;
}

export const Ai_Chart_V1: React.FC<Ai_ChartProps> = ({ ai_chart }) => {
  const plotContainer = useRef<HTMLDivElement>(null);
  const { x, y, config, layout } = JSON.parse(ai_chart);

  const data = useMemo(() => {
    return y.map((yValue: any[], idx: number) => ({
      x: x,
      y: yValue,
      ...config[idx],
    }));
  }, [x, y, config]);

  useEffect(() => {
    if (plotContainer.current) {
      Plotly.newPlot(plotContainer.current, data, layout);
    }
  }, [data, layout]);

  return (
    <div className="w-full h-full">
      <div ref={plotContainer} className="w-full h-full" />
    </div>
  );
};
