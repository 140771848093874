import { useEffect, useState } from 'react';
import { colorthemeAtom, userMetaAtom } from '../../../recoil/atoms/atoms';
import { useRecoilState } from 'recoil';
import { getUserMeta, uploadUserMeta } from '../../../apis/datasources/apis';
import Swal from 'sweetalert2';
import { Avatar } from 'antd/lib';
import { SHOW_WIP } from '../../../utils/constants';
import { PasswordInput } from './components/password_input';

export const ProfilePage = () => {
  const [isTargeted, setIsTargeted] = useState(false);

  const [theme, setTheme] = useRecoilState(colorthemeAtom);
  const [user, setUser] = useRecoilState(userMetaAtom);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    await uploadUserMeta(user).then(() => Swal.fire({
      text: 'Saved successfully!',
      timer: 2000
    })).catch((err) => {
      Swal.fire({
        text: 'Can\'t save - Service issue',
        timer: 2000
      });
      console.log(err.message);
    });
  };

  const handleCancel = async () => {
    await getUserMeta().then(setUser);
  };

  const toggleTheme = () => {
    // Toggle the theme and update local storage
    const newTheme = theme === 'light' ? 'dark' : 'light';
    localStorage.setItem('theme', newTheme);
    setTheme(newTheme);
  };

  useEffect(() => {
    setTheme(localStorage.getItem('theme'));
    getUserMeta().then(setUser);
  }, []);

  return (
    <div className="flex flex-col px-6 w-full h-full overflow-auto font-roboto">

      <div className="flex-1 flex mx-[150px] mt-6 h-full text-text_unselected">

        <div className="flex flex-col h-auto w-full gap-4">

          <h1 className="text-text_selected text-[28px] text-2xl font-medium mt-[60px]">
            Settings
          </h1>

          <div className="flex gap-6">
            <div
              className={`flex items-center justify-center h-6 w-56 ${isTargeted ? 'bg-border_unselected' : 'bg-border_selected'} rounded-lg cursor-pointer`}
              onClick={() => {
                setIsTargeted(false);
              }}
            >
              <span
                className={`${isTargeted ? 'text-text_unselected' : 'text-text_selected'} text-sm text-semibold`}
              >
                Profile
              </span>
            </div>
            {SHOW_WIP && <div
              className={`flex items-center justify-center h-6 w-56 ${isTargeted ? 'bg-border_selected' : 'bg-border_unselected'} rounded-lg cursor-pointer`}
              onClick={() => {
                setIsTargeted(true);
              }}
            >
              <span
                className={`${isTargeted ? 'text-text_selected' : 'text-text_unselected'} text-sm text-semibold`}
              >
                My Plan
              </span>
            </div>}
          </div>


          {/* Profile Container */}
          <div className="flex flex-col mt-6 gap-5">
            <div className="flex flex-row gap-5 items-center">

              {
                user?.photoUrl ? <div className="ml-2 w-[92px] h-[92px] rounded-full bg-border_selected flex justify-center items-center">
                  <Avatar size={60} src={<img src={user?.photoUrl} alt={user?.username?.at(0).toUpperCase()} />} />
                </div> : <div className="ml-2 w-[92px] h-[92px] rounded-full bg-border_selected flex justify-center items-center">
                  <Avatar size={60} className='bg-green text-layer1'>{user?.username?.at(0).toUpperCase()}</Avatar>
                </div>
              }

              <div className="text-sm">
                {/* <div className="text-text_selected">Picture</div> */}
                {/* Implement functions to handle these two buttons*/}
                {SHOW_WIP && <div className="flex justify-center items-center gap-3">
                  <button className="w-[119px] h-10 bg-border_unselected text-text_unselected rounded">Visualization</button>
                  <button className="w-[119px] h-10 border border-outline_unselected rounded">Remove</button>
                </div>}
              </div>
            </div>

            <div className="grid grid-cols-2 mt-4 gap-4">

              <div className="flex flex-col gap-1">
                <span>First Name</span>
                <input
                  value={user?.firstname ?? ''}
                  name='firstname'
                  onChange={handleInputChange}
                  className="bg-transparent text-sm text-text_unselected border border-outline_unselected h-10 px-4 py-2 rounded" type="text"
                />

              </div>

              <div className="flex flex-col gap-1">
                <span>Last Name</span>
                <input
                  value={user?.lastname ?? ''}
                  name='lastname'
                  onChange={handleInputChange}
                  className="bg-transparent text-sm text-text_unselected border border-outline_unselected h-10 px-4 py-2 rounded" type="text"
                />
              </div>

              <div className="flex flex-col gap-1">
                <span>Phone</span>
                <input
                  value={user?.phone ?? ''}
                  name='phone'
                  onChange={handleInputChange}
                  className="bg-transparent text-sm text-text_unselected border border-outline_unselected h-10 px-4 py-2 rounded" type="text"
                />
              </div>

              <div className="flex flex-col gap-1">
                <span>Email</span>
                <input
                  value={user?.email ?? ''}
                  name='email'
                  onChange={handleInputChange}
                  className="bg-transparent text-sm text-text_unselected border border-outline_unselected h-10 px-4 py-2 rounded" type="text"
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-5 items-center">
            <button className="w-[120.57px] h-10 px-4 py-2 bg-outline-unselected border border-active text-text_unselected rounded"
              onClick={handleCancel}>Cancel</button>
            <button onClick={handleSubmit} className="w-[120.57px] h-10 px-4 py-2 bg-active text-text_selected rounded">Save</button>
          </div>

          <hr className="border-outline_unselected my-6" />

          {/* Theme Container */}
          <div className="flex items-center justify-between">
            <span className="font-bold text-text_selected text-xl">Theme mode</span>

            <div className="flex items-center text-sm gap-3">
              <span className={theme === 'light' ? 'text-text_selected' : 'text-text_unselected'}>Light</span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value="toggle"
                  checked={theme === 'dark'}
                  onChange={toggleTheme}
                  className="sr-only peer"
                />
                <div className="w-20 h-8 bg-outline_hover peer-focus:outline-none rounded-full peer-checked:after:translate-x-12 after:absolute after:top-[4px] after:left-[4px] after:bg-border_unselected after:rounded-full after:h-6 after:w-6 after:transition-all peer-checked:bg-outline_hover"></div>
              </label>
              <span className={theme === 'dark' ? 'text-text_selected' : 'text-text_unselected'}>Dark</span>
            </div>
          </div>

          <hr className="border-outline_unselected my-6" />

          {/* Password Container */}
          <PasswordInput userId={user?.id} />

          <hr className="border-outline_unselected my-6" />
        </div>
      </div>
    </div>
  );
};
