import React, { useState, useReducer, useCallback } from 'react';
import { Form, Button } from 'antd';
import {
  includes,
  isFunction,
  filter,
  find,
  difference,
  isEmpty,
  mapValues,
} from 'lodash';
import helper from './DynamicFormHelper';
import DynamicFormField from './DynamicFormField';
import getFieldLabel from './getFieldLabel';
import Swal from 'sweetalert2';

const fieldRules = ({ type, required, minLength }) => {
  const requiredRule = required;
  const minLengthRule =
    minLength && includes(['text', 'email', 'password'], type);
  const emailTypeRule = type === 'email';

  return [
    requiredRule && { required, message: 'This field is required.' },
    minLengthRule && { min: minLength, message: 'This field is too short.' },
    emailTypeRule && {
      type: 'email',
      message: 'This field must be a valid email.',
    },
  ].filter((rule) => rule);
};

function normalizeEmptyValuesToNull(fields, values) {
  return mapValues(values, (value, key) => {
    const { initialValue } = find(fields, { name: key }) || {};
    if (
      (initialValue === null ||
        initialValue === undefined ||
        initialValue === '') &&
      value === ''
    ) {
      return null;
    }
    return value;
  });
}

function DynamicFormFields({ dbType, fields, feedbackIcons, form }) {
  return fields.map((field) => {
    const { name, type, initialValue, contentAfter } = field;
    const fieldLabel = getFieldLabel(field);

    const formItemProps = {
      name,
      className: 'mb-10',
      hasFeedback: type !== 'checkbox' && type !== 'file' && feedbackIcons,
      label: type === 'checkbox' ? '' : fieldLabel,
      rules: fieldRules(field),
      valuePropName: type === 'checkbox' ? 'checked' : 'value',
      initialValue,
    };

    const normFile = (e: any) => {
      return e?.fileList;
    };

    const otherFieldProps = {};

    if (type === 'file') {
      formItemProps.valuePropName = 'fileList';
      formItemProps.getValueFromEvent = normFile;
      otherFieldProps.maxCount = 1;
      // Note(Luke) Modified for legion to not get base64 of the file for csv or excel
      if (dbType === 'excel') {
        otherFieldProps.typeCheck = 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        // removing .xls cause it's not supported by duckdb
        otherFieldProps.accept = '.xlsx';
      } else if (dbType === 'csv') {
        otherFieldProps.typeCheck = 'text/csv';
        otherFieldProps.accept = '.csv';
      } else {
        formItemProps.valuePropName = 'data-value';
        formItemProps.getValueFromEvent = e => {
          if (e && e.fileList[0]) {
            helper.getBase64(e.file).then(value => {
              form.setFieldsValue({ [name]: value });
            });
          }
          return undefined;
        };
      }
    }

    return (
      <React.Fragment key={name}>
        <Form.Item {...formItemProps}>
          <DynamicFormField field={field} form={form} {...otherFieldProps} />
        </Form.Item>
        {isFunction(contentAfter)
          ? contentAfter(form.getFieldValue(name))
          : contentAfter}
      </React.Fragment>
    );
  });
}

const reducerForActionSet = (state, action) => {
  if (action.inProgress) {
    state.add(action.actionName);
  } else {
    state.delete(action.actionName);
  }
  return new Set(state);
};

function DynamicFormActions({ actions, isFormDirty }) {
  const [inProgressActions, setActionInProgress] = useReducer(
    reducerForActionSet,
    new Set()
  );

  const handleAction = useCallback((action) => {
    const actionName = action.name;
    if (isFunction(action.callback)) {
      setActionInProgress({ actionName, inProgress: true });
      action.callback(() => {
        setActionInProgress({ actionName, inProgress: false });
      });
    }
  }, []);

  return actions
    ? actions.map((action) => (
      <Button
        key={action.name}
        htmlType="button"
        className={`m-t-10 ${action.pullRight ? 'pull-right' : ''}`}
        type={action.type}
        disabled={isFormDirty && action.disableWhenDirty}
        loading={inProgressActions.has(action.name)}
        onClick={() => handleAction(action)}
      >
        {action.name}
      </Button>
    ))
    : null;
}

export default function DynamicForm({
  id,
  fields,
  actions,
  feedbackIcons,
  hideSubmitButton,
  defaultShowExtraFields,
  saveText,
  onSubmit,
  dbType,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [showExtraFields, setShowExtraFields] = useState(
    defaultShowExtraFields
  );
  const [form] = Form.useForm();
  const extraFields = filter(fields, { extra: true });
  const regularFields = difference(fields, extraFields);

  const handleFinish = useCallback(
    (values: Record<string, any>) => {
      setIsSubmitting(true);
      values = normalizeEmptyValuesToNull(fields, values);
      onSubmit(
        values,
        msg => {
          setIsSubmitting(false);
          setIsTouched(false); // reset form touched state
          Swal.fire('Success', msg);
        },
        msg => {
          setIsSubmitting(false);
          Swal.fire('Error', msg);
        }
      );
    },
    [fields, onSubmit]
  );

  const handleFinishFailed = useCallback(
    ({ errorFields }) => {
      form.scrollToField(errorFields[0].name);
    },
    [form]
  );

  return (
    <Form
      form={form}
      onFieldsChange={() => {
        setIsTouched(true);
      }}
      id={id}
      className="dynamic-form"
      layout="vertical"
      onFinish={handleFinish}
      onFinishFailed={handleFinishFailed}
    >
      <DynamicFormFields
        dbType={dbType}
        fields={regularFields}
        feedbackIcons={feedbackIcons}
        form={form}
      />
      {!isEmpty(extraFields) && (
        <div className="extra-options">
          <Button
            type="dashed"
            block
            className="extra-options-button"
            onClick={() =>
              setShowExtraFields(
                (currentShowExtraFields) => !currentShowExtraFields
              )
            }
          >
            Additional Settings
            <i
              className={`fa m-l-5 ${showExtraFields ? 'fa-caret-up' : 'fa-caret-down'
              }`}
              aria-hidden="true"
            />
          </Button>
          {showExtraFields && (
            <DynamicFormFields
              dbType={dbType}
              fields={extraFields}
              feedbackIcons={feedbackIcons}
              form={form}
            />
          )}
        </div>
      )}
      {!hideSubmitButton && (
        <Button
          className="w-100 mt-20"
          type="primary"
          htmlType="submit"
          disabled={isSubmitting}
        >
          {saveText}
        </Button>
      )}
      <DynamicFormActions actions={actions} isFormDirty={isTouched} />
    </Form>
  );
}
