import { useEffect } from 'react';
import {
  getAllDatasets,
  reloadDataset,
} from '../../../../apis/datasources/apis';
import { DatasetDto } from '../../../../apis/datasources/interfaces';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  addDatasetModalAtom,
  allDatasetsAtom,
  curDatasetAtom,
  datasetsAtom,
} from '../../../../recoil/atoms/atoms';
import { ReindexSvg } from '../../../../components/svg/reindex';
import Swal from 'sweetalert2';

export const Datasets = () => {
  const [allDatasets, setAllDatasets] = useRecoilState(allDatasetsAtom);
  const [addDatasetModal, setAddDatasetModal] =
    useRecoilState(addDatasetModalAtom);
  const setCurDataset = useSetRecoilState(curDatasetAtom);
  const setDatasetsMap = useSetRecoilState(datasetsAtom);

  useEffect(() => {
    getAllDatasets().then((dbList) => {
      setAllDatasets(dbList);
      const dbMap = new Map();
      dbList.forEach((dbItem) => {
        dbMap.set(dbItem.id, dbItem);
      });
      setDatasetsMap(dbMap);
    });
  }, [addDatasetModal]);

  const handleManageDataset = (dataset: DatasetDto) => {
    setCurDataset(dataset);
    setAddDatasetModal(true);
  };

  const handleReloadDataset = (dataset: DatasetDto) => {
    reloadDataset(dataset.id);
    Swal.fire(
      'Reindexing',
      'We are reindexing your data, may take sometime before it takes effect',
      'success'
    );
  };

  const renderDatasets = () => {
    return allDatasets.map((dataset) => {
      return (
        <div
          key={dataset.id}
          className="flex gap-x-6 px-16 py-7 justify-between items-center w-full h-12 rounded hover:bg-border_unselected"
        >
          <p className="w-1/6">{dataset.id}</p>
          <p className="w-1/6 text-text_selected font-bold">{dataset.name}</p>
          <div className="w-1/6 text-text_unselected">
            <div className="w-24 truncate" title={dataset.createdOn}>
              {dataset.createdOn}
            </div>
          </div>
          <div className="w-1/6">
            <div
              className="w-24 truncate"
              title={JSON.stringify(dataset.connJson)}
            >
              {JSON.stringify(dataset.connJson)}
            </div>
          </div>
          <p className="w-1/6 text-text_unselected">{dataset.type}</p>
          <div
            onClick={() => handleReloadDataset(dataset)}
            className="flex w-1/12 cursor-pointer"
            style={{ marginRight: '-10px' }}
          >
            <ReindexSvg />
          </div>
          <div
            className="flex w-1/12 items-center justify-center cursor-pointer"
            onClick={() => handleManageDataset(dataset)}
          >
            <div className="flex items-center justify-center px-6 h-8 border rounded border-active_hover">
              <span>Manage</span>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="px-6 h-[60vh] w-full">
      <div className="flex items-center gap-3 text-2xl font-bold font-mono">
        <span className="w-6 h-6 bg-dataset" />
        Documents
      </div>
      <div
        className={`bg-layer2 mt-3 w-full h-full rounded ${
          allDatasets.length === 0 && 'text-text_unselected'
        }`}
      >
        <div className="overflow-auto w-full h-full rounded">
          <div className="flex gap-x-6 px-16 justify-between items-center w-full h-12 rounded-t bg-layer2 font-bold">
            <span className="w-1/6">ID</span>
            <span className="w-1/6">Document Name</span>
            <span className="w-1/6">Modified At</span>
            <span className="w-1/6">Document</span>
            <span className="w-1/6">Type</span>
            <span className="w-1/12" />
            <span className="w-1/12" />
          </div>
          <div className="w-full border-t border-active_hover" />

          <div className="flex flex-col rounded h-full text-text_unselected">
            {allDatasets.length ? (
              <div className="flex-col items-center text-semibold">
                {renderDatasets()}
              </div>
            ) : (
              <div className="flex-1 flex items-center h-full justify-center text-semibold">
                <span>
                  There are currently no dataset connected to your account.
                  <br />
                  Lets start by importing a new dataset for enhanced results.
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="p-3 mt-[-3px] rounded-b bg-layer2">
          <div
            className="flex gap-1 items-center justify-center h-10 w-44 bg-border_unselected rounded cursor-pointer"
            onClick={() => {
              setCurDataset(null);
              setAddDatasetModal(true);
            }}
          >
            <div className="w-6 h-6 bg-blueplus" />
            <span className=" text-text_selected text-sm text-semibold">
              Add New Document
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
