import { Databases } from './components/databases';
import { Datasets } from './components/datasets';
import { useState } from 'react';

export const Datasources = () => {
  const [activeTab, setActiveTab] = useState('Databases'); // Initialize activeTab with 'Databases' as default

  return (
    <div className="h-full w-full overflow-auto">
      <div className="mt-12 mb-6 mx-auto flex flex-col self-center justify-center items-center w-11/12 h-4/6">
        <div className="flex gap-4 mb-4">
          <button
            className={`px-4 py-2 ${activeTab === 'Databases' ? 'bg-border_selected' : 'bg-border_unselected'}`}
            onClick={() => setActiveTab('Databases')}
          >
            Databases
          </button>
          <button
            className={`px-4 py-2 ${activeTab === 'Datasets' ? 'bg-border_selected' : 'bg-border_unselected'}`}
            onClick={() => setActiveTab('Datasets')}
          >
            Documents
          </button>
        </div>
        {activeTab === 'Databases' ? <Databases /> : <Datasets />}
      </div>
    </div>
  );
};
