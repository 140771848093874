import { PricingCard } from './components/pricing_card';
import { PRICING_CARDS_DATA } from './pricing_cards_data';
import { subscriptionInfoAtom } from '../../../recoil/atoms/atoms';
import { useRecoilState } from 'recoil';
import { useEffect } from 'react';
import { getLatestSubscription } from '../../../apis/billing/apis';

export const BillingPage = () => {
  const [subscriptionInfo, setSubscriptionInfo] =
    useRecoilState(subscriptionInfoAtom);

  useEffect(() => {
    getLatestSubscription().then(setSubscriptionInfo);
  }, []);

  const buildCardProps = (pricingCardProps) => {
    return { ...pricingCardProps, isCurrentPlan: subscriptionInfo && subscriptionInfo.subscription_level === pricingCardProps.title };
  };

  return (
    <div className="flex flex-col items-center bg-layer1 nowrap select-none overflow-auto h-full w-full">
      <h1 className="text-text_selected text-center text-2xl font-medium font-roboto mt-20">
        Choose The Best Pricing
      </h1>
      <div className="flex flex-wrap justify-center my-6 gap-10 max-w-[95%]">
        {PRICING_CARDS_DATA(process.env.REACT_APP_NODE_ENV === 'production').map((pricingCardProps, index) => (
          <PricingCard key={index}
            {...buildCardProps(pricingCardProps)} />
        ))}
      </div>
    </div>
  );
};
