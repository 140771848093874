import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import {
  addDatabaseModalAtom,
  addDatasetModalAtom,
  teamAccessModalAtom,
  authAtom,
  curConversationAtom,
  featureAtom,
  featureExpandAtom,
  mainOptionAtom,
  historyExpandAtom,
} from '../../../recoil/atoms/atoms';
import { FeatureOption, MainOption } from '../../main/navigation';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

export const Signout = () => {
  const setAuthInfo = useSetRecoilState(authAtom);
  const setMainOption = useSetRecoilState(mainOptionAtom);
  const setAddDatabaseModal = useSetRecoilState(addDatabaseModalAtom);
  const setAddDatasetModal = useSetRecoilState(addDatasetModalAtom);
  const setAddInviteeModal = useSetRecoilState(teamAccessModalAtom);
  const setFeatureOption = useSetRecoilState(featureAtom);
  const setFeatureExpand = useSetRecoilState(featureExpandAtom);
  const setHistoryExpand = useSetRecoilState(historyExpandAtom);
  const setCurConversation = useSetRecoilState(curConversationAtom);

  const { logout } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    setAuthInfo(null);
    setFeatureOption(FeatureOption.NONE);
    setCurConversation(null);
    setFeatureExpand(false);
    setHistoryExpand(false);
    setAddDatabaseModal(false);
    setAddDatasetModal(false);
    setAddInviteeModal(false);
    setMainOption(MainOption.Features);
    localStorage.removeItem('authInfo');

    const signOut = async () => {
      setAuthInfo(null);
      localStorage.removeItem('authInfo');
      logout({ logoutParams: { returnTo: `${window.location.origin}/landing` } });
    };

    setTimeout(() => {
      navigate('/landing');
    }, 1000);
    signOut();
  }, [logout]);

  return (
    <div className="bg-layer2 text-text_selected flex flex-col items-center justify-center">
      <div className="text-4xl font-bold">Signing out...</div>
    </div>
  );
};
