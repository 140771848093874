import { useEffect, useState } from 'react';
import { isEmpty, includes, uniqueId } from 'lodash';
import Button from 'antd/lib/button';
import List from 'antd/lib/list';
import Modal from 'antd/lib/modal';
import Input from 'antd/lib/input';
import Steps from 'antd/lib/steps';
import { addDatabaseRedashAtom, curDatabaseAtom } from '../../../../recoil/atoms/atoms';
import { useRecoilState } from 'recoil';
import PreviewCard from './preview_card';
import helper, { Target } from './dynamic-forms/DynamicFormHelper';
import DynamicForm from './dynamic-forms/DynamicForm';
import { deleteDatabase, upsertDatabase } from '../../../../apis/datasources/apis';
import { DatabaseDto, DatabaseType } from '../../../../apis/datasources/interfaces';

const { Step } = Steps;
const { Search } = Input;

const StepEnum = {
  SELECT_TYPE: 0,
  CONFIGURE_IT: 1,
  DONE: 2,
};

const AddDatabaseRedash = ({ data, sourceType = 'Database' }) => {
  const [searchText, setSearchText] = useState('');
  const [selectedType, setSelectedType] = useState(null);
  const [savingSource, setSavingSource ] = useState(false);
  const [currentStep, setCurrentStep] = useState(StepEnum.SELECT_TYPE);
  const [deletingDatabase, setDeletingDatabase] = useState(false);
  const [curDatabase, setCurDatabase] = useRecoilState(curDatabaseAtom);
  const [addDatabaseRedash, setAddDatabaseRedash] = useRecoilState(
    addDatabaseRedashAtom
  );

  useEffect(() => {
    if (curDatabase) {
      const curType = data.find((type) => type.type === curDatabase.type);
      setSelectedType(curType);
      setCurrentStep(StepEnum.CONFIGURE_IT);
    }
  }, []);

  const formId = uniqueId('sourceForm');

  const selectType = (type) => {
    setSelectedType(type);
    setCurrentStep(StepEnum.CONFIGURE_IT);
  };

  const resetType = () => {
    if (currentStep === StepEnum.CONFIGURE_IT) {
      setSearchText('');
      setSelectedType(null);
      setCurrentStep(StepEnum.SELECT_TYPE);
    }
  };

  const renderTypeSelector = () => {
    const filteredTypes = data.filter(
      (type) =>
        isEmpty(searchText) ||
        includes(type.name.toLowerCase(), searchText.toLowerCase())
    );
    return (
      <div>
        <Search
          placeholder="Search..."
          aria-label="Search"
          onChange={(e) => setSearchText(e.target.value)}
          autoFocus
          data-test="SearchSource"
        />
        <div
          className="overflow-y-auto p-2 mt-3"
          style={{ minHeight: '30vh', maxHeight: '40vh' }}
        >
          {isEmpty(filteredTypes) ? (
            <></>
          ) : (
            <List
              size="small"
              dataSource={filteredTypes}
              renderItem={(item) => renderItem(item)}
            />
          )}
        </div>
      </div>
    );
  };

  const createSource = (values, successCallback, errorCallback) => {
    if (!savingSource) {
      setSavingSource(true);
      const database: DatabaseDto = {
        name: values.name,
        type: selectedType.type as DatabaseType,
        connJson: values ? Object.fromEntries(Object.entries(values).filter(([key]) => (key !== 'name' && key !== 'file'))) : null,
        createdOn: Date.now().toString(),
      };

      if (curDatabase) {
        database.id = curDatabase.id;
      }

      if (values['file']) {
        database.file = values.file[0].originFileObj;
        database.connJson['file'] = values.file;
      }

      upsertDatabase(database).then(() => {
        successCallback('Saved.');
      }).catch((err) => {
        errorCallback('Failed saving: ' + err);
      }).finally(() => {
        setAddDatabaseRedash(false);
        setCurDatabase(null);
        setSavingSource(false);
      });
    }
  };

  const renderForm = () => {
    let fields;

    if (curDatabase) {
      const target: Target = {
        id: curDatabase.id,
        name: curDatabase.name,
        options: curDatabase.connJson
      };
      fields = helper.getFields(selectedType, target);
    } else {
      fields = helper.getFields(selectedType);
    }

    return (
      <div>
        <div className="flex justify-center items-center">
          {/* <img
            className="p-5"
            src={`${selectedType.type}.png`}
            alt={selectedType.name}
            width="48"
          /> */}
          <h4 className="m-0">{selectedType.name}</h4>
        </div>

        {/* <div className="text-right">
          {HELP_TRIGGER_TYPES[selectedType.type] && (
            <HelpTrigger className="f-13" type={selectedType.type}>
              Setup Instructions <i className="fa fa-question-circle" aria-hidden="true" />
              <span className="sr-only">(help)</span>
            </HelpTrigger>
          )}
        </div> */}

        <DynamicForm
          id={formId}
          fields={fields}
          onSubmit={createSource}
          feedbackIcons
          hideSubmitButton
          dbType={selectedType.type}
        />
        {selectedType.type === 'athena' && (
          <small>Additional instructions for Athena configuration.</small>
        )}
      </div>
    );
  };

  const renderItem = (item) => {
    return (
      <List.Item
        className="px-10 hover:cursor-pointer"
        onClick={() => selectType(item)}
      >
        <PreviewCard body={item.name}></PreviewCard>
      </List.Item>
    );
  };

  const handleDeleteClick = () => {
    deleteDatabase(curDatabase.id).then(() => {
      setAddDatabaseRedash(false);
      setCurDatabase(null);
    });
  };

  return (
    <>
      <Modal
        open={addDatabaseRedash}
        onCancel={() => {
          setAddDatabaseRedash(false);
          setCurDatabase(null);
        }}
        title={curDatabase ? `Manage ${sourceType}` : `Create a New ${sourceType}`}
        footer={
          currentStep === StepEnum.SELECT_TYPE
            ? [
              <Button
                key="cancel"
                onClick={() => {
                  setAddDatabaseRedash(false);
                  setCurDatabase(null);
                }}
                data-test="CreateSourceCancelButton"
              >
                Cancel
              </Button>,
              <Button key="submit" disabled>
                Create
              </Button>,
            ]
            : [
              <Button key="previous" onClick={resetType} disabled={curDatabase ? true : false}>
                Previous
              </Button>,
              <Button
                key="submit"
                htmlType="submit"
                form={formId}
                loading={savingSource}
              >
                {curDatabase ? 'Save' : 'Create'}
              </Button>,
              curDatabase && <Button
                key="delete"
                onClick={() => setDeletingDatabase(true)}
              >
                Delete
              </Button>,
            ]
        }
      >
        <div>
          <Steps
            className="mb-3 mt-6"
            size="small"
            current={currentStep}
            progressDot
          >
            {currentStep === StepEnum.CONFIGURE_IT ? (
              <Step
                title={<a>Type Selection</a>}
                className="clickable"
                onClick={resetType}
              />
            ) : (
              <Step title="Type Selection" />
            )}
            <Step title="Configuration" />
            {/* <Step title="Done" /> */}
          </Steps>

          {currentStep === StepEnum.SELECT_TYPE && renderTypeSelector()}
          {currentStep !== StepEnum.SELECT_TYPE && renderForm()}
        </div>
      </Modal>
      <Modal
        open={deletingDatabase}
        onCancel={() => {
          setDeletingDatabase(false);
        }}
        footer={[
          <Button key="cancel" onClick={() => setDeletingDatabase(false)}>
            Cancel
          </Button>,
          <Button key="delete" onClick={handleDeleteClick}>
            Delete
          </Button>,]
        }>
        <div className="my-10 w-full flex flex-col items-center justify-center text-center">
          <span className="mt-6 text-lg font-bold">
            Are you sure you want to delete this database?
          </span>
        </div>
      </Modal>
    </>
  );
};

export default AddDatabaseRedash;
