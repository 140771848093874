import { useRecoilValue } from 'recoil';
import { curConversationAtom } from '../../../../recoil/atoms/atoms';
import { GeneralChatSvg } from '../../../../components/svg/generalchat';

export const Title = ({ displayType, datasource }) => {
  const curConversation = useRecoilValue(curConversationAtom);

  return (
    <div className="self-start bg-sidebar text-white px-16 min-h-[4.5rem] w-full flex place-items-center">
      <div className="container flex items-center justify-between text-gray-400">
        <div className="flex flex-none items-center w-[28%] overflow-hidden mr-10">
          <div className="-mr-3">
            <GeneralChatSvg />
          </div>
          <span className="font-bold truncate text-xl pl-2 ml-3">
            {curConversation.conversationName}
          </span>
        </div>

        {datasource && (
          <div className="flex flex-none items-center justify-end w-[37%] overflow-hidden ml-auto">
            <div className="flex items-center mr-6">
              <span className="text-xs sm:text-sm whitespace-nowrap mr-2 flex-shrink-0">
                {displayType === 'dataset' ? 'Dataset: ' : 'Database: '}
              </span>
              <span className="font-medium text-xs sm:text-sm truncate max-w-[150px]">
                {datasource.name}
              </span>
            </div>
            <div className="flex items-center">
              <span className="text-xs sm:text-sm truncate mr-2">Type:</span>
              <span className="font-medium text-xs sm:text-sm truncate uppercase">
                {datasource.type}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
