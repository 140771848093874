import React, { useState, useEffect } from 'react';
import GridLayout from 'react-grid-layout';
import { Layout } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { DashboardWidget } from './components/dashboard_widget';
import { dashboardDto, widgetConfigDto } from '../../../apis/dashboard/interfaces';
import { getDashboard, updateDashboard, getAllWidgetConfigs } from '../../../apis/dashboard/api';

interface DashboardProps {
  dashboardId: string;
}

export const Dashboard: React.FC<DashboardProps> = ({ dashboardId }) => {
  const [dashboard, setDashboard] = useState<dashboardDto | null>(null);
  const [widgetConfigs, setWidgetConfigs] = useState<widgetConfigDto[]>([]);
  const [layout, setLayout] = useState<Layout[]>([]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const [dashboardData, configs] = await Promise.all([
          getDashboard(dashboardId),
          getAllWidgetConfigs(dashboardId)
        ]);

        setDashboard(dashboardData);
        setWidgetConfigs(configs);

        if (!dashboardData.layout || dashboardData.layout.length === 0) {
          const defaultLayout = configs.map((widget, index) => ({
            i: widget.widgetId,
            x: (index * 4) % 12,
            y: Math.floor(index / 3) * 4,
            w: 4,
            h: 4,
          }));
          setLayout(defaultLayout);
        } else {
          setLayout(dashboardData.layout);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchDashboardData();
  }, [dashboardId]);

  const handleLayoutChange = async (newLayout: Layout[]) => {
    setLayout(newLayout);
    try {
      await updateDashboard(dashboardId, { layout:newLayout});
    } catch (err) {
      console.error('Failed to update layout:', err);
    }
  };



  return (
    <div className="h-full w-full p-4">
      <div className="mb-4 flex justify-between items-center">
        <h1 className="text-2xl font-bold">{dashboard?.name}</h1>
      </div>
      <GridLayout
        className="layout"
        layout={layout}
        onLayoutChange={handleLayoutChange}
        cols={12}
        rowHeight={30}
        width={1200}
        draggableHandle=".widget-handle"
      >
        {widgetConfigs.map((widget) => (
          <div key={widget.widgetId}>
            <DashboardWidget widget={widget} />
          </div>
        ))}
      </GridLayout>
    </div>
  );
};
