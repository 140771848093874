export const SUPPORTED_DATABASES = [
  {
    name: 'Amazon Athena',
    type: 'athena',
    configuration_schema: {
      type: 'object',
      properties: {
        region: { type: 'string', title: 'AWS Region' },
        aws_access_key: { type: 'string', title: 'AWS Access Key' },
        aws_secret_key: { type: 'string', title: 'AWS Secret Key' },
        s3_staging_dir: {
          type: 'string',
          title: 'S3 Staging (Query Results) Bucket Path',
        },
        schema: { type: 'string', title: 'Schema Name', default: 'default' },
        glue: { type: 'boolean', title: 'Use Glue Data Catalog' },
        work_group: {
          type: 'string',
          title: 'Athena Work Group',
          default: 'primary',
        },
        cost_per_tb: {
          type: 'number',
          title: 'Athena cost per Tb scanned (USD)',
          default: 5,
        },
        encryption_option: { type: 'string', title: 'Encryption Option' },
        kms_key: { type: 'string', title: 'KMS Key' },
      },
      required: ['region', 's3_staging_dir'],
      extra_options: ['glue', 'cost_per_tb', 'encryption_option', 'kms_key'],
      order: [
        'region',
        'aws_access_key',
        'aws_secret_key',
        's3_staging_dir',
        'schema',
        'work_group',
        'cost_per_tb',
      ],
      secret: ['aws_secret_key'],
    },
  },
  {
    name: 'Amazon CloudWatch',
    type: 'cloudwatch',
    configuration_schema: {
      type: 'object',
      properties: {
        region: { type: 'string', title: 'AWS Region' },
        aws_access_key: { type: 'string', title: 'AWS Access Key' },
        aws_secret_key: { type: 'string', title: 'AWS Secret Key' },
      },
      required: ['region', 'aws_access_key', 'aws_secret_key'],
      order: ['region', 'aws_access_key', 'aws_secret_key'],
      secret: ['aws_secret_key'],
    },
  },
  {
    name: 'Amazon CloudWatch Logs Insights',
    type: 'cloudwatch_insights',
    configuration_schema: {
      type: 'object',
      properties: {
        region: { type: 'string', title: 'AWS Region' },
        aws_access_key: { type: 'string', title: 'AWS Access Key' },
        aws_secret_key: { type: 'string', title: 'AWS Secret Key' },
      },
      required: ['region', 'aws_access_key', 'aws_secret_key'],
      order: ['region', 'aws_access_key', 'aws_secret_key'],
      secret: ['aws_secret_key'],
    },
  },
  {
    name: 'Amazon Elasticsearch Service',
    type: 'aws_es',
    configuration_schema: {
      type: 'object',
      properties: {
        server: { type: 'string', title: 'Endpoint' },
        region: { type: 'string' },
        access_key: { type: 'string', title: 'Access Key' },
        secret_key: { type: 'string', title: 'Secret Key' },
        use_aws_iam_profile: { type: 'boolean', title: 'Use AWS IAM Profile' },
      },
      secret: ['secret_key'],
      order: [
        'server',
        'region',
        'access_key',
        'secret_key',
        'use_aws_iam_profile',
      ],
      required: ['server', 'region'],
    },
  },
  {
    name: 'Apache Drill',
    type: 'drill',
    configuration_schema: {
      type: 'object',
      properties: {
        url: { type: 'string', title: 'Drill URL' },
        username: { type: 'string', title: 'Username' },
        password: { type: 'string', title: 'Password' },
        allowed_schemas: {
          type: 'string',
          title: 'List of schemas to use in schema browser (comma separated)',
        },
      },
      secret: ['password'],
      order: ['url', 'username', 'password', 'allowed_schemas'],
      required: ['url'],
    },
  },
  {
    name: 'Apache Ignite',
    type: 'ignite',
    configuration_schema: {
      type: 'object',
      properties: {
        user: { type: 'string' },
        password: { type: 'string' },
        server: { type: 'string', default: '127.0.0.1:10800' },
        tls: {
          type: 'boolean',
          default: false,
          title: 'Use SSL/TLS connection',
        },
        schema: { type: 'string', title: 'Schema Name', default: 'PUBLIC' },
        distributed_joins: {
          type: 'boolean',
          title: 'Allow distributed joins',
          default: false,
        },
        enforce_join_order: {
          type: 'boolean',
          title: 'Enforce join order',
          default: false,
        },
        lazy: { type: 'boolean', title: 'Lazy query execution', default: true },
        gridgain: {
          type: 'boolean',
          title: 'Use GridGain libraries',
          default: true,
        },
      },
      required: ['server'],
      secret: ['password'],
    },
  },
  {
    name: 'ArangoDB',
    type: 'arangodb',
    configuration_schema: {
      type: 'object',
      properties: {
        user: { type: 'string' },
        password: { type: 'string' },
        host: { type: 'string', default: '127.0.0.1' },
        port: { type: 'number', default: 8529 },
        dbname: { type: 'string', title: 'Database Name' },
        timeout: {
          type: 'number',
          default: 0.0,
          title: 'AQL Timeout in seconds (0 = no timeout)',
        },
      },
      order: ['host', 'port', 'user', 'password', 'dbname'],
      required: ['host', 'user', 'password', 'dbname'],
      secret: ['password'],
    },
  },
  {
    name: 'Axibase Time Series Database',
    type: 'axibasetsd',
    configuration_schema: {
      type: 'object',
      properties: {
        protocol: { type: 'string', title: 'Protocol', default: 'http' },
        hostname: {
          type: 'string',
          title: 'Host',
          default: 'axibase_tsd_hostname',
        },
        port: { type: 'number', title: 'Port', default: 8088 },
        username: { type: 'string' },
        password: { type: 'string', title: 'Password' },
        timeout: { type: 'number', default: 600, title: 'Connection Timeout' },
        min_insert_date: {
          type: 'string',
          title: 'Metric Minimum Insert Date',
        },
        expression: { type: 'string', title: 'Metric Filter' },
        limit: { type: 'number', default: 5000, title: 'Metric Limit' },
        trust_certificate: { type: 'boolean', title: 'Trust SSL Certificate' },
      },
      required: ['username', 'password', 'hostname', 'protocol', 'port'],
      secret: ['password'],
    },
  },
  {
    name: 'Azure Data Explorer (Kusto)',
    type: 'azure_kusto',
    configuration_schema: {
      type: 'object',
      properties: {
        cluster: { type: 'string' },
        azure_ad_client_id: { type: 'string', title: 'Azure AD Client ID' },
        azure_ad_client_secret: {
          type: 'string',
          title: 'Azure AD Client Secret',
        },
        azure_ad_tenant_id: { type: 'string', title: 'Azure AD Tenant Id' },
        database: { type: 'string' },
      },
      required: [
        'cluster',
        'azure_ad_client_id',
        'azure_ad_client_secret',
        'azure_ad_tenant_id',
        'database',
      ],
      order: [
        'cluster',
        'azure_ad_client_id',
        'azure_ad_client_secret',
        'azure_ad_tenant_id',
        'database',
      ],
      secret: ['azure_ad_client_secret'],
    },
  },
  {
    name: 'BigQuery',
    type: 'bigquery',
    configuration_schema: {
      type: 'object',
      properties: {
        projectId: { type: 'string', title: 'Project ID' },
        jsonKeyFile: {
          type: 'string',
          title: 'JSON Key File (ADC is used if omitted)',
        },
        totalMBytesProcessedLimit: {
          type: 'number',
          title: 'Scanned Data Limit (MB)',
        },
        userDefinedFunctionResourceUri: {
          type: 'string',
          title:
            'UDF Source URIs (i.e. gs://bucket/date_utils.js, gs://bucket/string_utils.js )',
        },
        useStandardSql: {
          type: 'boolean',
          title: 'Use Standard SQL',
          default: true,
        },
        location: { type: 'string', title: 'Processing Location' },
        loadSchema: { type: 'boolean', title: 'Load Schema', default: true },
        maximumBillingTier: { type: 'number', title: 'Maximum Billing Tier' },
        useQueryAnnotation: {
          type: 'boolean',
          title: 'Use Query Annotation',
          default: false,
        },
      },
      required: ['projectId'],
      order: [
        'projectId',
        'jsonKeyFile',
        'loadSchema',
        'useStandardSql',
        'location',
        'totalMBytesProcessedLimit',
        'maximumBillingTier',
        'userDefinedFunctionResourceUri',
        'useQueryAnnotation',
      ],
      secret: ['jsonKeyFile'],
    },
  },
  {
    name: 'Cassandra',
    type: 'Cassandra',
    configuration_schema: {
      type: 'object',
      properties: {
        host: { type: 'string' },
        port: { type: 'number', default: 9042 },
        keyspace: { type: 'string', title: 'Keyspace name' },
        username: { type: 'string', title: 'Username' },
        password: { type: 'string', title: 'Password' },
        protocol: { type: 'number', title: 'Protocol Version', default: 3 },
        timeout: { type: 'number', title: 'Timeout', default: 10 },
        useSsl: { type: 'boolean', title: 'Use SSL', default: false },
        sslCertificateFile: { type: 'string', title: 'SSL Certificate File' },
        sslProtocol: {
          type: 'string',
          title: 'SSL Protocol',
          enum: [
            'PROTOCOL_SSLv23',
            'PROTOCOL_TLS',
            'PROTOCOL_TLS_CLIENT',
            'PROTOCOL_TLS_SERVER',
            'PROTOCOL_TLSv1',
            'PROTOCOL_TLSv1_1',
            'PROTOCOL_TLSv1_2',
          ],
        },
      },
      required: ['keyspace', 'host', 'useSsl'],
      secret: ['sslCertificateFile'],
    },
  },
  {
    name: 'ClickHouse',
    type: 'clickhouse',
    configuration_schema: {
      type: 'object',
      properties: {
        url: { type: 'string', default: 'http://127.0.0.1:8123' },
        user: { type: 'string', default: 'default' },
        password: { type: 'string' },
        dbname: { type: 'string', title: 'Database Name' },
        timeout: { type: 'number', title: 'Request Timeout', default: 30 },
        verify: {
          type: 'boolean',
          title: 'Verify SSL certificate',
          default: true,
        },
      },
      order: ['url', 'user', 'password', 'dbname'],
      required: ['dbname'],
      extra_options: ['timeout', 'verify'],
      secret: ['password'],
    },
  },
  {
    name: 'CockroachDB',
    type: 'cockroach',
    configuration_schema: {
      type: 'object',
      properties: {
        user: { type: 'string' },
        password: { type: 'string' },
        host: { type: 'string', default: '127.0.0.1' },
        port: { type: 'number', default: 5432 },
        dbname: { type: 'string', title: 'Database Name' },
        sslmode: {
          type: 'string',
          title: 'SSL Mode',
          default: 'prefer',
          extendedEnum: [
            { value: 'disable', name: 'Disable' },
            { value: 'allow', name: 'Allow' },
            { value: 'prefer', name: 'Prefer' },
            { value: 'require', name: 'Require' },
            { value: 'verify-ca', name: 'Verify CA' },
            { value: 'verify-full', name: 'Verify Full' },
          ],
        },
        sslrootcertFile: { type: 'string', title: 'SSL Root Certificate' },
        sslcertFile: { type: 'string', title: 'SSL Client Certificate' },
        sslkeyFile: { type: 'string', title: 'SSL Client Key' },
      },
      order: ['host', 'port', 'user', 'password'],
      required: ['dbname'],
      secret: ['password', 'sslrootcertFile', 'sslcertFile', 'sslkeyFile'],
      extra_options: [
        'sslmode',
        'sslrootcertFile',
        'sslcertFile',
        'sslkeyFile',
      ],
    },
  },
  {
    name: 'Couchbase',
    type: 'couchbase',
    configuration_schema: {
      type: 'object',
      properties: {
        protocol: { type: 'string', default: 'http' },
        host: { type: 'string' },
        port: {
          type: 'string',
          title: 'Port (Defaults: 8095 - Analytics, 8093 - N1QL)',
          default: '8095',
        },
        user: { type: 'string' },
        password: { type: 'string' },
      },
      required: ['host', 'user', 'password'],
      order: ['protocol', 'host', 'port', 'user', 'password'],
      secret: ['password'],
    },
  },
  {
    name: 'CSV',
    type: 'csv',
    configuration_schema: {
      type: 'object',
      properties: {
        file: { type: 'file' },
      },
      required: ['file'],
    },
  },
  {
    name: 'Databend',
    type: 'databend',
    configuration_schema: {
      type: 'object',
      properties: {
        host: { type: 'string', default: 'localhost' },
        port: { type: 'string', default: '8000' },
        username: { type: 'string' },
        password: { type: 'string', default: '' },
        database: { type: 'string' },
        secure: { type: 'boolean', default: false },
      },
      order: ['username', 'password', 'host', 'port', 'database'],
      required: ['username', 'database'],
      secret: ['password'],
    },
  },
  {
    name: 'Databricks',
    type: 'databricks',
    configuration_schema: {
      type: 'object',
      properties: {
        host: { type: 'string' },
        http_path: { type: 'string', title: 'HTTP Path' },
        http_password: { type: 'string', title: 'Access Token' },
      },
      order: ['host', 'http_path', 'http_password'],
      secret: ['http_password'],
      required: ['host', 'http_path', 'http_password'],
    },
  },
  {
    name: 'Dgraph',
    type: 'dgraph',
    configuration_schema: {
      type: 'object',
      properties: {
        user: { type: 'string' },
        password: { type: 'string' },
        servers: { type: 'string' },
      },
      order: ['servers', 'user', 'password'],
      required: ['servers'],
      secret: ['password'],
    },
  },
  {
    name: 'Druid',
    type: 'druid',
    configuration_schema: {
      type: 'object',
      properties: {
        host: { type: 'string', default: 'localhost' },
        port: { type: 'number', default: 8082 },
        scheme: { type: 'string', default: 'http' },
        user: { type: 'string' },
        password: { type: 'string' },
      },
      order: ['scheme', 'host', 'port', 'user', 'password'],
      required: ['host'],
      secret: ['password'],
    },
  },
  {
    name: 'eccenca Corporate Memory (with SPARQL)',
    type: 'corporate_memory',
    configuration_schema: {
      type: 'object',
      properties: {
        CMEM_BASE_URI: { type: 'string', title: 'Base URL' },
        OAUTH_GRANT_TYPE: {
          type: 'string',
          title: 'Grant Type',
          default: 'client_credentials',
          extendedEnum: [
            { value: 'client_credentials', name: 'client_credentials' },
            { value: 'password', name: 'password' },
          ],
        },
        OAUTH_CLIENT_ID: {
          type: 'string',
          title: 'Client ID (e.g. cmem-service-account)',
          default: 'cmem-service-account',
        },
        OAUTH_CLIENT_SECRET: {
          type: 'string',
          title:
            "Client Secret - only needed for grant type 'client_credentials'",
        },
        OAUTH_USER: {
          type: 'string',
          title: "User account - only needed for grant type 'password'",
        },
        OAUTH_PASSWORD: {
          type: 'string',
          title: "User Password - only needed for grant type 'password'",
        },
        SSL_VERIFY: {
          type: 'boolean',
          title: 'Verify SSL certificates for API requests',
          default: true,
        },
        REQUESTS_CA_BUNDLE: {
          type: 'string',
          title: 'Path to the CA Bundle file (.pem)',
        },
      },
      required: ['CMEM_BASE_URI', 'OAUTH_GRANT_TYPE', 'OAUTH_CLIENT_ID'],
      secret: ['OAUTH_CLIENT_SECRET', 'OAUTH_PASSWORD'],
      extra_options: [
        'OAUTH_GRANT_TYPE',
        'OAUTH_USER',
        'OAUTH_PASSWORD',
        'SSL_VERIFY',
        'REQUESTS_CA_BUNDLE',
      ],
    },
  },
  // Note(Luke) seems like this is being deprecated
  // {
  //   name: 'Elasticsearch',
  //   type: 'elasticsearch',
  //   configuration_schema: {
  //     type: 'object',
  //     properties: {
  //       server: { type: 'string', title: 'Base URL' },
  //       basic_auth_user: { type: 'string', title: 'Basic Auth User' },
  //       basic_auth_password: { type: 'string', title: 'Basic Auth Password' },
  //     },
  //     order: ['server', 'basic_auth_user', 'basic_auth_password'],
  //     secret: ['basic_auth_password'],
  //     required: ['server'],
  //   },
  //   deprecated: true,
  // },
  {
    name: 'Elasticsearch',
    type: 'elasticsearch2',
    configuration_schema: {
      type: 'object',
      properties: {
        url: { type: 'string', title: 'URL base path' },
        username: { type: 'string', title: 'HTTP Basic Auth Username' },
        password: { type: 'string', title: 'HTTP Basic Auth Password' },
      },
      secret: ['password'],
      order: ['url', 'username', 'password'],
      required: ['url'],
    },
  },
  {
    name: 'Exasol',
    type: 'exasol',
    configuration_schema: {
      type: 'object',
      properties: {
        user: { type: 'string' },
        password: { type: 'string' },
        host: { type: 'string' },
        port: { type: 'number', default: 8563 },
        encrypted: { type: 'boolean', title: 'Enable SSL Encryption' },
      },
      required: ['host', 'port', 'user', 'password'],
      order: ['host', 'port', 'user', 'password', 'encrypted'],
      secret: ['password'],
    },
  },
  {
    name: 'Excel',
    type: 'excel',
    configuration_schema: {
      type: 'object',
      properties: {
        file: { type: 'file' },
      },
      required: ['file'],
    },
  },
  {
    name: 'Google Analytics',
    type: 'google_analytics',
    configuration_schema: {
      type: 'object',
      properties: {
        jsonKeyFile: {
          type: 'string',
          title: 'JSON Key File (ADC is used if omitted)',
        },
      },
      required: [],
      secret: ['jsonKeyFile'],
    },
  },
  {
    name: 'Google Analytics 4',
    type: 'google_analytics4',
    configuration_schema: {
      type: 'object',
      properties: {
        propertyId: { type: 'number', title: 'Property Id' },
        jsonKeyFile: {
          type: 'string',
          title: 'JSON Key File (ADC is used if omitted)',
        },
      },
      required: ['propertyId'],
      secret: ['jsonKeyFile'],
    },
  },
  {
    name: 'Google Search Console',
    type: 'google_search_console',
    configuration_schema: {
      type: 'object',
      properties: {
        siteURL: { type: 'string', title: 'Site URL' },
        jsonKeyFile: {
          type: 'string',
          title: 'JSON Key File (ADC is used if omitted)',
        },
      },
      required: [],
      secret: ['jsonKeyFile'],
    },
  },
  {
    name: 'Google Sheets',
    type: 'google_spreadsheets',
    configuration_schema: {
      type: 'object',
      properties: {
        jsonKeyFile: {
          type: 'string',
          title: 'JSON Key File (ADC is used if omitted)',
        },
      },
      required: [],
      secret: ['jsonKeyFile'],
    },
  },
  {
    name: 'Graphite',
    type: 'graphite',
    configuration_schema: {
      type: 'object',
      properties: {
        url: { type: 'string' },
        username: { type: 'string' },
        password: { type: 'string' },
        verify: { type: 'boolean', title: 'Verify SSL certificate' },
      },
      required: ['url'],
      secret: ['password'],
    },
  },
  {
    name: 'Hive',
    type: 'hive',
    configuration_schema: {
      type: 'object',
      properties: {
        host: { type: 'string' },
        port: { type: 'number' },
        database: { type: 'string' },
        username: { type: 'string' },
      },
      order: ['host', 'port', 'database', 'username'],
      required: ['host'],
    },
  },
  {
    name: 'Hive (HTTP)',
    type: 'hive_http',
    configuration_schema: {
      type: 'object',
      properties: {
        host: { type: 'string' },
        port: { type: 'number' },
        database: { type: 'string' },
        username: { type: 'string' },
        http_scheme: {
          type: 'string',
          title: 'HTTP Scheme (http or https)',
          default: 'https',
        },
        http_path: { type: 'string', title: 'HTTP Path' },
        http_password: { type: 'string', title: 'Password' },
      },
      order: [
        'host',
        'port',
        'http_path',
        'username',
        'http_password',
        'database',
        'http_scheme',
      ],
      secret: ['http_password'],
      required: ['host', 'http_path'],
    },
  },
  {
    name: 'Impala',
    type: 'impala',
    configuration_schema: {
      type: 'object',
      properties: {
        host: { type: 'string' },
        port: { type: 'number' },
        protocol: {
          type: 'string',
          extendedEnum: [
            { value: 'beeswax', name: 'Beeswax' },
            { value: 'hiveserver2', name: 'Hive Server 2' },
          ],
          title: 'Protocol',
        },
        database: { type: 'string' },
        use_ldap: { type: 'boolean' },
        use_ssl: { type: 'boolean' },
        ldap_user: { type: 'string' },
        ldap_password: { type: 'string' },
        timeout: { type: 'number' },
      },
      required: ['host'],
      secret: ['ldap_password'],
    },
  },
  {
    name: 'InfluxDB',
    type: 'influxdb',
    configuration_schema: {
      type: 'object',
      properties: { url: { type: 'string' } },
      required: ['url'],
    },
  },
  {
    name: 'JIRA (JQL)',
    type: 'jirajql',
    configuration_schema: {
      type: 'object',
      properties: {
        url: { type: 'string', title: 'JIRA URL' },
        username: { type: 'string', title: 'Username' },
        password: { type: 'string', title: 'API Token' },
      },
      secret: ['password'],
      order: ['url', 'username', 'password'],
      required: ['url', 'username', 'password'],
    },
  },
  {
    name: 'JSON',
    type: 'json',
    configuration_schema: {
      type: 'object',
      properties: {
        username: { type: 'string', title: 'HTTP Basic Auth Username' },
        password: { type: 'string', title: 'HTTP Basic Auth Password' },
      },
      secret: ['password'],
      order: ['username', 'password'],
    },
  },
  {
    name: 'Kibana',
    type: 'kibana',
    configuration_schema: {
      type: 'object',
      properties: {
        server: { type: 'string', title: 'Base URL' },
        basic_auth_user: { type: 'string', title: 'Basic Auth User' },
        basic_auth_password: { type: 'string', title: 'Basic Auth Password' },
      },
      order: ['server', 'basic_auth_user', 'basic_auth_password'],
      secret: ['basic_auth_password'],
      required: ['server'],
    },
    deprecated: true,
  },
  {
    name: 'Kylin',
    type: 'kylin',
    configuration_schema: {
      type: 'object',
      properties: {
        user: { type: 'string', title: 'Kylin Username' },
        password: { type: 'string', title: 'Kylin Password' },
        url: {
          type: 'string',
          title: 'Kylin API URL',
          default: 'http://kylin.example.com/kylin/',
        },
        project: { type: 'string', title: 'Kylin Project' },
      },
      order: ['url', 'project', 'user', 'password'],
      required: ['url', 'project', 'user', 'password'],
      secret: ['password'],
    },
  },
  {
    name: 'MemSQL',
    type: 'memsql',
    configuration_schema: {
      type: 'object',
      properties: {
        host: { type: 'string' },
        port: { type: 'number' },
        user: { type: 'string' },
        password: { type: 'string' },
      },
      required: ['host', 'port'],
      secret: ['password'],
    },
  },
  {
    name: 'Microsoft SQL Server',
    type: 'mssql',
    configuration_schema: {
      type: 'object',
      properties: {
        user: { type: 'string' },
        password: { type: 'string' },
        server: { type: 'string', default: '127.0.0.1' },
        port: { type: 'number', default: 1433 },
        tds_version: { type: 'string', default: '7.0', title: 'TDS Version' },
        charset: { type: 'string', default: 'UTF-8', title: 'Character Set' },
        db: { type: 'string', title: 'Database Name' },
      },
      required: ['db'],
      secret: ['password'],
    },
  },
  {
    name: 'Microsoft SQL Server (ODBC)',
    type: 'mssql_odbc',
    configuration_schema: {
      type: 'object',
      properties: {
        server: { type: 'string' },
        port: { type: 'number', default: 1433 },
        user: { type: 'string' },
        password: { type: 'string' },
        db: { type: 'string', title: 'Database Name' },
        charset: { type: 'string', default: 'UTF-8', title: 'Character Set' },
        use_ssl: { type: 'boolean', title: 'Use SSL', default: false },
        verify_ssl: {
          type: 'boolean',
          title: 'Verify SSL certificate',
          default: true,
        },
      },
      order: [
        'server',
        'port',
        'user',
        'password',
        'db',
        'charset',
        'use_ssl',
        'verify_ssl',
      ],
      required: ['server', 'user', 'password', 'db'],
      secret: ['password'],
      extra_options: ['verify_ssl', 'use_ssl'],
    },
  },
  {
    name: 'MongoDB',
    type: 'mongodb',
    configuration_schema: {
      type: 'object',
      properties: {
        connectionString: { type: 'string', title: 'Connection String' },
        username: { type: 'string' },
        password: { type: 'string' },
        dbName: { type: 'string', title: 'Database Name' },
        replicaSetName: { type: 'string', title: 'Replica Set Name' },
        readPreference: {
          type: 'string',
          extendedEnum: [
            { value: 'primaryPreferred', name: 'Primary Preferred' },
            { value: 'primary', name: 'Primary' },
            { value: 'secondary', name: 'Secondary' },
            { value: 'secondaryPreferred', name: 'Secondary Preferred' },
            { value: 'nearest', name: 'Nearest' },
          ],
          title: 'Replica Set Read Preference',
        },
      },
      secret: ['password'],
      required: ['connectionString', 'dbName'],
    },
  },
  {
    name: 'MySQL',
    type: 'mysql',
    configuration_schema: {
      type: 'object',
      properties: {
        host: { type: 'string', default: '127.0.0.1' },
        user: { type: 'string' },
        passwd: { type: 'string', title: 'Password' },
        db: { type: 'string', title: 'Database name' },
        port: { type: 'number', default: 3306 },
        connect_timeout: {
          type: 'number',
          default: 60,
          title: 'Connection Timeout',
        },
        charset: { type: 'string', default: 'utf8' },
        use_unicode: { type: 'boolean', default: true },
        use_ssl: { type: 'boolean', title: 'Use SSL' },
        ssl_cacert: {
          type: 'string',
          title: 'Path to CA certificate file to verify peer against (SSL)',
        },
        ssl_cert: {
          type: 'string',
          title: 'Path to client certificate file (SSL)',
        },
        ssl_key: { type: 'string', title: 'Path to private key file (SSL)' },
      },
      order: [
        'host',
        'port',
        'user',
        'passwd',
        'db',
        'connect_timeout',
        'charset',
        'use_unicode',
      ],
      required: ['db'],
      secret: ['passwd'],
    },
  },
  {
    name: 'MySQL (Amazon RDS)',
    type: 'rds_mysql',
    configuration_schema: {
      type: 'object',
      properties: {
        host: { type: 'string' },
        user: { type: 'string' },
        passwd: { type: 'string', title: 'Password' },
        db: { type: 'string', title: 'Database name' },
        port: { type: 'number', default: 3306 },
        use_ssl: { type: 'boolean', title: 'Use SSL' },
      },
      order: ['host', 'port', 'user', 'passwd', 'db'],
      required: ['db', 'user', 'passwd', 'host'],
      secret: ['passwd'],
    },
  },
  {
    name: 'Netezza',
    type: 'nz',
    configuration_schema: {
      type: 'object',
      properties: {
        user: { type: 'string' },
        password: { type: 'string' },
        host: { type: 'string', default: '127.0.0.1' },
        port: { type: 'number', default: 5480 },
        database: { type: 'string', title: 'Database Name', default: 'system' },
      },
      order: ['host', 'port', 'user', 'password', 'database'],
      required: ['user', 'password', 'database'],
      secret: ['password'],
    },
  },
  {
    name: 'Open Distro SQL Elasticsearch',
    type: 'elasticsearch2_OpenDistroSQLElasticSearch',
    configuration_schema: {
      type: 'object',
      properties: {
        url: { type: 'string', title: 'URL base path' },
        username: { type: 'string', title: 'HTTP Basic Auth Username' },
        password: { type: 'string', title: 'HTTP Basic Auth Password' },
      },
      secret: ['password'],
      order: ['url', 'username', 'password'],
      required: ['url'],
    },
  },
  {
    name: 'Oracle',
    type: 'oracle',
    configuration_schema: {
      type: 'object',
      properties: {
        user: { type: 'string' },
        password: { type: 'string' },
        host: {
          type: 'string',
          title:
            'Host: To use a DSN Service Name instead, use the text string `_useservicename` in the host name field.',
        },
        port: { type: 'number' },
        servicename: { type: 'string', title: 'DSN Service Name' },
        encoding: { type: 'string' },
      },
      required: ['servicename', 'user', 'password', 'host', 'port'],
      extra_options: ['encoding'],
      secret: ['password'],
    },
  },
  {
    name: 'Phoenix',
    type: 'phoenix',
    configuration_schema: {
      type: 'object',
      properties: { url: { type: 'string' } },
      required: ['url'],
    },
  },
  {
    name: 'Pinot',
    type: 'pinot',
    configuration_schema: {
      type: 'object',
      properties: {
        brokerHost: { type: 'string', default: '' },
        brokerPort: { type: 'number', default: 8099 },
        brokerScheme: { type: 'string', default: 'http' },
        controllerURI: { type: 'string', default: '' },
        username: { type: 'string' },
        password: { type: 'string' },
      },
      order: [
        'brokerScheme',
        'brokerHost',
        'brokerPort',
        'controllerURI',
        'username',
        'password',
      ],
      required: ['brokerHost', 'controllerURI'],
      secret: ['password'],
    },
  },
  {
    name: 'PostgreSQL',
    type: 'pg',
    configuration_schema: {
      type: 'object',
      properties: {
        user: { type: 'string' },
        password: { type: 'string' },
        host: { type: 'string', default: '127.0.0.1' },
        port: { type: 'number', default: 5432 },
        dbname: { type: 'string', title: 'Database Name' },
        sslmode: {
          type: 'string',
          title: 'SSL Mode',
          default: 'prefer',
          extendedEnum: [
            { value: 'disable', name: 'Disable' },
            { value: 'allow', name: 'Allow' },
            { value: 'prefer', name: 'Prefer' },
            { value: 'require', name: 'Require' },
            { value: 'verify-ca', name: 'Verify CA' },
            { value: 'verify-full', name: 'Verify Full' },
          ],
        },
        sslrootcertFile: { type: 'string', title: 'SSL Root Certificate' },
        sslcertFile: { type: 'string', title: 'SSL Client Certificate' },
        sslkeyFile: { type: 'string', title: 'SSL Client Key' },
      },
      order: ['host', 'port', 'user', 'password'],
      required: ['dbname'],
      secret: ['password', 'sslrootcertFile', 'sslcertFile', 'sslkeyFile'],
      extra_options: [
        'sslmode',
        'sslrootcertFile',
        'sslcertFile',
        'sslkeyFile',
      ],
    },
  },
  {
    name: 'Presto',
    type: 'presto',
    configuration_schema: {
      type: 'object',
      properties: {
        host: { type: 'string' },
        protocol: { type: 'string', default: 'http' },
        port: { type: 'number' },
        schema: { type: 'string' },
        catalog: { type: 'string' },
        username: { type: 'string' },
        password: { type: 'string' },
      },
      order: [
        'host',
        'protocol',
        'port',
        'username',
        'password',
        'schema',
        'catalog',
      ],
      required: ['host'],
    },
  },
  {
    name: 'Prometheus',
    type: 'prometheus',
    configuration_schema: {
      type: 'object',
      properties: { url: { type: 'string', title: 'Prometheus API URL' } },
      required: ['url'],
    },
  },
  {
    name: 'Qubole',
    type: 'qubole',
    configuration_schema: {
      type: 'object',
      properties: {
        query_type: {
          type: 'string',
          title: 'Query Type (quantum / presto / hive)',
          default: 'hive',
        },
        endpoint: {
          type: 'string',
          title: 'API Endpoint',
          default: 'https://api.qubole.com',
        },
        token: { type: 'string', title: 'Auth Token' },
        cluster: { type: 'string', title: 'Cluster Label', default: 'default' },
      },
      order: ['query_type', 'endpoint', 'token', 'cluster'],
      required: ['endpoint', 'token'],
      secret: ['token'],
    },
  },
  {
    name: 'Query Results',
    type: 'results',
    configuration_schema: { type: 'object', properties: {} },
  },
  {
    name: 'Redshift',
    type: 'redshift',
    configuration_schema: {
      type: 'object',
      properties: {
        user: { type: 'string' },
        password: { type: 'string' },
        host: { type: 'string' },
        port: { type: 'number' },
        dbname: { type: 'string', title: 'Database Name' },
        sslmode: { type: 'string', title: 'SSL Mode', default: 'prefer' },
        adhoc_query_group: {
          type: 'string',
          title: 'Query Group for Adhoc Queries',
          default: 'default',
        },
        scheduled_query_group: {
          type: 'string',
          title: 'Query Group for Scheduled Queries',
          default: 'default',
        },
      },
      order: [
        'host',
        'port',
        'user',
        'password',
        'dbname',
        'sslmode',
        'adhoc_query_group',
        'scheduled_query_group',
      ],
      required: ['dbname', 'user', 'password', 'host', 'port'],
      secret: ['password'],
    },
  },
  {
    name: 'Redshift (with IAM User/Role)',
    type: 'redshift_iam',
    configuration_schema: {
      type: 'object',
      properties: {
        rolename: { type: 'string', title: 'IAM Role Name' },
        aws_region: { type: 'string', title: 'AWS Region' },
        aws_access_key_id: { type: 'string', title: 'AWS Access Key ID' },
        aws_secret_access_key: {
          type: 'string',
          title: 'AWS Secret Access Key',
        },
        clusterid: { type: 'string', title: 'Redshift Cluster ID' },
        user: { type: 'string' },
        host: { type: 'string' },
        port: { type: 'number' },
        dbname: { type: 'string', title: 'Database Name' },
        sslmode: { type: 'string', title: 'SSL Mode', default: 'prefer' },
        adhoc_query_group: {
          type: 'string',
          title: 'Query Group for Adhoc Queries',
          default: 'default',
        },
        scheduled_query_group: {
          type: 'string',
          title: 'Query Group for Scheduled Queries',
          default: 'default',
        },
      },
      order: [
        'rolename',
        'aws_region',
        'aws_access_key_id',
        'aws_secret_access_key',
        'clusterid',
        'host',
        'port',
        'user',
        'dbname',
        'sslmode',
        'adhoc_query_group',
        'scheduled_query_group',
      ],
      required: ['dbname', 'user', 'host', 'port', 'aws_region'],
      secret: ['aws_secret_access_key'],
    },
  },
  {
    name: 'Rockset',
    type: 'rockset',
    configuration_schema: {
      type: 'object',
      properties: {
        api_server: {
          type: 'string',
          title: 'API Server',
          default: 'https://api.rs2.usw2.rockset.com',
        },
        api_key: { title: 'API Key', type: 'string' },
      },
      order: ['api_key', 'api_server'],
      required: ['api_server', 'api_key'],
      secret: ['api_key'],
    },
  },
  {
    name: 'Salesforce',
    type: 'salesforce',
    configuration_schema: {
      type: 'object',
      properties: {
        username: { type: 'string' },
        password: { type: 'string' },
        token: { type: 'string', title: 'Security Token' },
        sandbox: { type: 'boolean' },
        api_version: {
          type: 'string',
          title: 'Salesforce API Version',
          default: '38.0',
        },
      },
      required: ['username', 'password'],
      secret: ['password', 'token'],
    },
  },
  {
    name: 'ScyllaDB',
    type: 'scylla',
    configuration_schema: {
      type: 'object',
      properties: {
        host: { type: 'string' },
        port: { type: 'number', default: 9042 },
        keyspace: { type: 'string', title: 'Keyspace name' },
        username: { type: 'string', title: 'Username' },
        password: { type: 'string', title: 'Password' },
        protocol: { type: 'number', title: 'Protocol Version', default: 3 },
        timeout: { type: 'number', title: 'Timeout', default: 10 },
        useSsl: { type: 'boolean', title: 'Use SSL', default: false },
        sslCertificateFile: { type: 'string', title: 'SSL Certificate File' },
        sslProtocol: {
          type: 'string',
          title: 'SSL Protocol',
          enum: [
            'PROTOCOL_SSLv23',
            'PROTOCOL_TLS',
            'PROTOCOL_TLS_CLIENT',
            'PROTOCOL_TLS_SERVER',
            'PROTOCOL_TLSv1',
            'PROTOCOL_TLSv1_1',
            'PROTOCOL_TLSv1_2',
          ],
        },
      },
      required: ['keyspace', 'host', 'useSsl'],
      secret: ['sslCertificateFile'],
    },
  },
  {
    name: 'Snowflake',
    type: 'snowflake',
    configuration_schema: {
      type: 'object',
      properties: {
        account: { type: 'string' },
        user: { type: 'string' },
        password: { type: 'string' },
        warehouse: { type: 'string' },
        database: { type: 'string' },
        region: { type: 'string', default: 'us-west' },
        lower_case_columns: {
          type: 'boolean',
          title: 'Lower Case Column Names in Results',
          default: false,
        },
        host: { type: 'string' },
      },
      order: [
        'account',
        'user',
        'password',
        'warehouse',
        'database',
        'region',
        'host',
      ],
      required: ['user', 'password', 'account', 'database', 'warehouse'],
      secret: ['password'],
      extra_options: ['host'],
    },
  },
  {
    name: 'SPARQL Endpoint',
    type: 'sparql_endpoint',
    configuration_schema: {
      type: 'object',
      properties: {
        SPARQL_BASE_URI: { type: 'string', title: 'Base URL' },
        SSL_VERIFY: {
          type: 'boolean',
          title: 'Verify SSL certificates for API requests',
          default: true,
        },
      },
      required: ['SPARQL_BASE_URI'],
      secret: [],
      extra_options: ['SSL_VERIFY'],
    },
  },
  {
    name: 'Sqlite',
    type: 'sqlite',
    configuration_schema: {
      type: 'object',
      properties: { dbpath: { type: 'string', title: 'Database Path' } },
      required: ['dbpath'],
    },
  },
  {
    name: 'Tinybird',
    type: 'tinybird',
    configuration_schema: {
      type: 'object',
      properties: {
        url: { type: 'string', default: 'https://api.tinybird.co' },
        token: { type: 'string', title: 'Auth Token' },
        timeout: { type: 'number', title: 'Request Timeout', default: 30 },
        verify: {
          type: 'boolean',
          title: 'Verify SSL certificate',
          default: true,
        },
      },
      order: ['url', 'token'],
      required: ['token'],
      extra_options: ['timeout', 'verify'],
      secret: ['token'],
    },
  },
  {
    name: 'TreasureData',
    type: 'treasuredata',
    configuration_schema: {
      type: 'object',
      properties: {
        endpoint: { type: 'string' },
        apikey: { type: 'string' },
        type: { type: 'string' },
        db: { type: 'string', title: 'Database Name' },
        get_schema: {
          type: 'boolean',
          title: 'Auto Schema Retrieval',
          default: false,
        },
      },
      secret: ['apikey'],
      required: ['apikey', 'db'],
    },
  },
  {
    name: 'Uptycs',
    type: 'uptycs',
    configuration_schema: {
      type: 'object',
      properties: {
        url: { type: 'string' },
        customer_id: { type: 'string' },
        key: { type: 'string' },
        verify_ssl: {
          type: 'boolean',
          default: true,
          title: 'Verify SSL Certificates',
        },
        secret: { type: 'string' },
      },
      order: ['url', 'customer_id', 'key', 'secret'],
      required: ['url', 'customer_id', 'key', 'secret'],
      secret: ['secret', 'key'],
    },
  },
  {
    name: 'Url',
    type: 'url',
    configuration_schema: {
      type: 'object',
      properties: {
        url: { type: 'string', title: 'URL base path' },
        username: { type: 'string', title: 'HTTP Basic Auth Username' },
        password: { type: 'string', title: 'HTTP Basic Auth Password' },
      },
      secret: ['password'],
      order: ['url', 'username', 'password'],
    },
    deprecated: true,
  },
  {
    name: 'Vertica',
    type: 'vertica',
    configuration_schema: {
      type: 'object',
      properties: {
        host: { type: 'string' },
        user: { type: 'string' },
        password: { type: 'string', title: 'Password' },
        database: { type: 'string', title: 'Database name' },
        port: { type: 'number' },
        read_timeout: { type: 'number', title: 'Read Timeout' },
        connection_timeout: { type: 'number', title: 'Connection Timeout' },
      },
      required: ['database'],
      order: [
        'host',
        'port',
        'user',
        'password',
        'database',
        'read_timeout',
        'connection_timeout',
      ],
      secret: ['password'],
    },
  },
  {
    name: 'X-Pack SQL Elasticsearch',
    type: 'elasticsearch2_XPackSQLElasticSearch',
    configuration_schema: {
      type: 'object',
      properties: {
        url: { type: 'string', title: 'URL base path' },
        username: { type: 'string', title: 'HTTP Basic Auth Username' },
        password: { type: 'string', title: 'HTTP Basic Auth Password' },
      },
      secret: ['password'],
      order: ['url', 'username', 'password'],
      required: ['url'],
    },
  },
  {
    name: 'Yandex AppMetrica',
    type: 'yandex_appmetrika',
    configuration_schema: {
      type: 'object',
      properties: { token: { type: 'string', title: 'OAuth Token' } },
      secret: ['token'],
      required: ['token'],
    },
  },
  {
    name: 'Yandex Metrica',
    type: 'yandex_metrika',
    configuration_schema: {
      type: 'object',
      properties: { token: { type: 'string', title: 'OAuth Token' } },
      secret: ['token'],
      required: ['token'],
    },
  },
];
