import { useState } from 'react';
import { onbroadingModalAtom } from '../../../recoil/atoms/atoms';
import { useSetRecoilState } from 'recoil';
import { Step1 } from './components/step1';
import { Step2 } from './components/step2';
import { Step3 } from './components/step3';
import { Step4 } from './components/step4';

export const Stepper = () => {
  const nSteps = 4;
  const [curStep, setCurStep] = useState(0);
  const setOnbroadingModal = useSetRecoilState(onbroadingModalAtom);

  const onFinishOnboarding = () => {
    setOnbroadingModal(false);
    localStorage.setItem('onboardShown', 'true');
  };

  const renderStep = () => {
    switch (curStep) {
    case 0:
      return <Step1 />;
    case 1:
      return <Step2 />;
    case 2:
      return <Step3 />;
    case 3:
      return <Step4 />;
    default:
      return <Step1 />;
    }
  };

  return (
    <div className="absolute w-screen h-screen bg-black bg-opacity-70 flex items-center justify-center">
      <div className="w-[620px] h-[544px] bg-layer1 rounded p-10 flex flex-col justify-between">
        <div className="flex gap-4 justify-center">
          {Array.from({ length: nSteps }, (_, index) => index).map(
            (_, index) => (
              <div
                key={index}
                className={`w-2 h-2 ${curStep === index ? 'bg-white' : 'bg-layer2'
                }  rounded-full`}
              />
            )
          )}
        </div>
        <div className="w-[540px] h-[400px]">{renderStep()}</div>
        <div className="mt-6 w-full flex justify-between items-center select-none">
          <div
            className="w-28 h-10 border border-active_hover rounded flex items-center justify-center cursor-pointer"
            onClick={() => setCurStep((step) => (step - 1 + nSteps) % nSteps)}
          >
            <span className="text-text_unselected font-semibold text-sm">Back</span>
          </div>

          <div className="flex gap-4">
            <div
              className="w-28 h-10 border border-active_hover rounded flex items-center justify-center cursor-pointer"
              onClick={() => onFinishOnboarding()}
            >
              <span className="text-text_unselected font-semibold text-sm">Skip</span>
            </div>
            {curStep === nSteps - 1 ? (
              <div
                className="ml-2 w-28 h-10 bg-border_unselected rounded flex items-center justify-center cursor-pointer"
                onClick={() => onFinishOnboarding()}
              >
                <span className="text-text_unselected font-semibold text-sm">
                  Get Started
                </span>
              </div>
            ) : (
              <div
                className="ml-2 w-28 h-10 bg-border_unselected rounded flex items-center justify-center cursor-pointer"
                onClick={() => setCurStep((step) => (step + 1) % nSteps)}
              >
                <span className="text-text_unselected font-semibold text-sm">Next</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
