import { instance } from '../base/instance';
import { createDashboardDto, dashboardDto, updateDashboardDto, widgetConfigDto, widgetDto,createWidgetDto } from './interfaces';

export const createDashboard = async (
  dashboard: createDashboardDto
): Promise<dashboardDto> => {
  const res = await instance.post('/dashboard', dashboard);
  return res.data;
};

export const getAllDashboards = async (): Promise<dashboardDto[]> => {
  const res = await instance.get('/dashboard');
  return res.data;
};

export const getDashboard = async (id: string): Promise<dashboardDto> => {
  const res = await instance.get(`/dashboard/${id}`);
  return res.data;
};

export const updateDashboard = async (
  id: string,
  data: updateDashboardDto
): Promise<dashboardDto> => {
  const res = await instance.put(`/dashboard/${id}`, data);
  return res.data;
};

export const deleteDashboard = async (id: string): Promise<void> => {
  await instance.delete(`/dashboard/${id}`);
};

export const getAllWidgetConfigs = async (
  dashboardId: string
): Promise<widgetConfigDto[]> => {
  const res = await instance.get(`/dashboard/${dashboardId}/widget-configs`);
  return res.data;
};

// export const updateDashboardLayout = async (
//   id: string,
//   layout: Layout[]
// ): Promise<dashboardDto> => {
//   const res = await instance.put(`/dashboard/${id}/layout`, { layout });
//   return res.data;
// };

export const createWidget = async (
  data: createWidgetDto
): Promise<widgetDto> => {
  const res = await instance.post('/widget', data);
  return res.data;
};
