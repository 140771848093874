import React from 'react';
import { Ai_Chart_V1 } from './charts/widget_chart';
import { widgetConfigDto } from '../../../../apis/dashboard/interfaces';

interface DashboardWidgetProps {
  widget: widgetConfigDto;
}

export const DashboardWidget: React.FC<DashboardWidgetProps> = ({ widget }) => {
  const config = JSON.parse(widget.config);

  return (
    <div className="bg-white rounded-lg shadow h-full">
      <div className="widget-handle p-2 cursor-move border-b">
        <h3 className="font-semibold">
          {config.layout.title || 'Chart'}
        </h3>
      </div>
      <div className="p-4 h-[calc(100%-40px)]">
        <Ai_Chart_V1 ai_chart={widget.config} />
      </div>
    </div>
  );
};
