import { DatabaseDto, DatasetDto } from '../datasources/interfaces';

export enum ConversationType {
  TEXT2SQL = 'text2sql',
  GENERAL = 'general',
  DATAENTRY = 'dataentry',
}

export interface CreateConversationDto {
  conversationName: string;
  conversationType: ConversationType;

  text2sql?: StartConversationText2SqlDto;
  qa?: StartConversationQaDto;
  dataentry?: StartConversationDataEntryDto;
}

export class StartConversationText2SqlDto {
  databaseId?: string;
  databaseIds?: string[];
}

export class StartConversationQaDto {
  datasetIds?: string[];
}

export class StartConversationDataEntryDto {
  databaseId?: string;
  datasetIds?: string[];
  datasetName?: string;
}

export class Text2SqlRequest {
  executeSQL: boolean;
  useAgent: boolean;
}

export class GeneralChatRequest {}

export class DataEntryRequest {
  datasetName?: string;
}

export interface ConversationDto {
  chatHistory: DialogDto[];
  conversationDescription: string;
  conversationId: string;
  conversationName: string;
  conversationType: ConversationType;
  createdTimestamp: string;
  updateTimestamp: string;
  connectedDatabases: DatabaseDto[];
  connectedDatasets: DatasetDto[];
}

export interface CreateDialogDto {
  message: string;
  conversationType: string;
  text2SqlRequest?: Text2SqlRequest;
  generalChatRequest?: GeneralChatRequest;
  dataEntryRequest?: DataEntryRequest;
  isAsync?: boolean;
}

export enum DialogStatusEnum {
  FINISHED = 'FINISHED',
  IN_PROGRESS = 'PROCESSING',
}

export interface DialogDto {
  userInput: string;
  responses?: DialogResponse[];
  dialogId: string;
  userTimestamp: string;
  dialogRating?: DialogRating;
  dialogStatus?: DialogStatusEnum;
  followUps: FollowUp[];
}

// copied from webserver/src/entities/dialog.entity.ts
export interface DialogResponse {
  // text messages
  text?: string;
  internal_text?: string; // this is not shown to users by default

  // non text payload
  // only one are populated in paraent responses list
  sql_query?: string;
  sql_result?: SqlResult;

  // more than one can be populated in paraent responses list
  ai_chart?: string;
  analytic_charts?: string[];
  text_list?: string[];

  // error messages if something not intended happened
  error_text?: string;
}

export interface SqlResult {
  rows: any[];
  columns?: SqlColumn[];
}

export interface SqlColumn {
  name: string;
  type: string;
}

export class FollowUp {
  text: string;
}

export enum DialogRating {
  UNSET = 'unset',
  THUMBSUP = 'thumbsup',
  THUMBSDOWN = 'thumbsdown',
}

export interface VoidResponseDto {
  success: boolean;
  message: string;
}
