import { useEffect, useState } from 'react';
import { PasswordDto } from '../../../../apis/auth/interfaces';
import { resetPassword } from '../../../../apis/auth/apis';
import Swal from 'sweetalert2';

interface PasswordInputProps {
    userId: string;
}

export const PasswordInput = ({ userId = '' }: PasswordInputProps) => {
  const [isPassword, setIsPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordHint, setShowPasswordHint] = useState(false);
  const [checkPasswordLength, setCheckPasswordLength] = useState(false);
  const [checkPasswordUpperLetter, setCheckPasswordUpperLetter] = useState(false);
  const [checkPasswordLowerLetter, setCheckPasswordLowerLetter] = useState(false);
  const [checkPasswordNumber, setCheckPasswordNumber] = useState(false);
  const [checkPasswordSpecialLetter, setCheckPasswordSpecialLetter] = useState(false);

  const onPasswordInput = (event) => {
    setPassword(event.target.value);
  };

  const onEyeClick = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  const handlePasswordChange = async () => {
    const passwordDto: PasswordDto = {
      password: password,
    };
    const now = new Date().toDateString();
    await resetPassword(userId, now, passwordDto).then(() =>
      Swal.fire({
        text: 'Saved successfully!',
        timer: 2000
      })).catch((err) => {
      Swal.fire({
        text: 'Can\'t save - Service issue',
        timer: 2000
      });
      console.log(err.message);
    });
  };

  useEffect(() => {
    setCheckPasswordLength(password.length >= 8);
    setCheckPasswordUpperLetter(password !== password.toLowerCase());
    setCheckPasswordLowerLetter(password !== password.toUpperCase());
    setCheckPasswordNumber(/\d/.test(password));
    setCheckPasswordSpecialLetter(/[!-/:-@[-`{-~]/.test(password));
  }, [password]);


  return (
    <div className='flex flex-col w-full h-auto'>
      <div className="flex items-center justify-between">
        <span className="font-bold text-text_selected text-xl">Password</span>

        {!isPassword ? (
          <button
            className="flex items-center justify-center w-48 h-10 rounded-sm bg-border_unselected text-text_unselected text-sm"
            onClick={() => { setIsPassword(true); }}
          >
                        Change Password
          </button>
        ) : (
          <div className="flex justify-end gap-5 items-center">
            <button
              className="w-[120.57px] h-10 px-4 py-2 bg-outline-unselected border border-active text-text_unselected rounded"
              onClick={() => {
                setIsPassword(false);
                setPassword('');
              }}
            >
                            Cancel
            </button>
            <button
              className="w-[120.57px] h-10 px-4 py-2 bg-outline-unselected border border-active text-text_unselected rounded"
              onClick={handlePasswordChange}
            >
                            Confirm
            </button>
          </div>)
        }
      </div>

      {isPassword && <div>
        <div className="flex justify-between items-center mt-2 w-full h-12 rounded border-solid border-active_hover focus-within:border-active border bg-transparent">
          <div className="grow h-full pl-6 pr-6">
            <input
              className="h-full w-full text-sm font-normal bg-transparent outline-none"
              placeholder="Password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={onPasswordInput}
              onFocusCapture={() => setShowPasswordHint(true)}
            />
          </div>
          {showPassword ? (
            <div
              className="mr-6 w-6 h-6 bg-eye bg-cover cursor-pointer"
              onClick={onEyeClick}
            />
          ) : (
            <div
              className="mr-6 w-6 h-6 bg-eyeslash bg-cover cursor-pointer"
              onClick={onEyeClick}
            />
          )}
        </div>
        {showPasswordHint && (
          <div className='text-left w-full text-sm font-normal pt-1 pl -1 text-text_unselected_secondary'>
            <span className={`${checkPasswordLength && 'text-text_selected'}`}>At least 8 characters</span>
            <span> | </span>
            <span className={`${checkPasswordUpperLetter && 'text-text_selected'}`}>One uppercase</span>
            <span> | </span>
            <span className={`${checkPasswordLowerLetter && 'text-text_selected'}`}>One lowercase</span>
            <span> | </span>
            <span className={`${checkPasswordNumber && 'text-text_selected'}`}>One number</span>
            <span> | </span>
            <span className={`${checkPasswordSpecialLetter && 'text-text_selected'}`}>One special character</span>
          </div>
        )}
      </div>
      }
    </div>
  );
};
